// Add any app-wide styles. Should be kept to a minimum.

@font-face {
  font-family: "DIN Next";
  font-weight: 400;
  src: url("./fonts/139e8e25-0eea-4cb4-a5d4-79048803e73d.eot?#iefix");
  src: url("./fonts/139e8e25-0eea-4cb4-a5d4-79048803e73d.eot?#iefix")
      format("eot"),
    url("./fonts/094b15e3-94bd-435b-a595-d40edfde661a.woff2") format("woff2"),
    url("./fonts/7e5fbe11-4858-4bd1-9ec6-a1d9f9d227aa.woff") format("woff"),
    url("./fonts/0f11eff9-9f05-46f5-9703-027c510065d7.ttf") format("truetype"),
    url("./fonts/48b61978-3f30-4274-823c-5cdcd1876918.svg#48b61978-3f30-4274-823c-5cdcd1876918")
      format("svg");
}
@font-face {
  font-family: "DIN Next";
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/df24d5e8-5087-42fd-99b1-b16042d666c8.eot?#iefix");
  src: url("./fonts/df24d5e8-5087-42fd-99b1-b16042d666c8.eot?#iefix")
      format("eot"),
    url("./fonts/46251881-ffe9-4bfb-99c7-d6ce3bebaf3e.woff2") format("woff2"),
    url("./fonts/790ebbf2-62c5-4a32-946f-99d405f9243e.woff") format("woff"),
    url("./fonts/d28199e6-0f4a-42df-97f4-606701c6f75a.ttf") format("truetype"),
    url("./fonts/37a462c0-d86e-492c-b9ab-35e6bd417f6c.svg#37a462c0-d86e-492c-b9ab-35e6bd417f6c")
      format("svg");
}
@font-face {
  font-weight: 500;
  font-family: "DIN Next";
  src: url("./fonts/c5b058fc-55ce-4e06-a175-5c7d9a7e90f4.eot?#iefix");
  src: url("./fonts/c5b058fc-55ce-4e06-a175-5c7d9a7e90f4.eot?#iefix")
      format("eot"),
    url("./fonts/7b29ae40-30ff-4f99-a2b9-cde88669fa2f.woff2") format("woff2"),
    url("./fonts/bf73077c-e354-4562-a085-f4703eb1d653.woff") format("woff"),
    url("./fonts/0ffa6947-5317-4d07-b525-14d08a028821.ttf") format("truetype"),
    url("./fonts/9e423e45-5450-4991-a157-dbe6cf61eb4e.svg#9e423e45-5450-4991-a157-dbe6cf61eb4e")
      format("svg");
}
@font-face {
  font-family: "DIN Next";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/9897c008-fd65-48a4-afc7-36de2fea97b9.eot?#iefix");
  src: url("./fonts/9897c008-fd65-48a4-afc7-36de2fea97b9.eot?#iefix")
      format("eot"),
    url("./fonts/c7717981-647d-4b76-8817-33062e42d11f.woff2") format("woff2"),
    url("./fonts/b852cd4c-1255-40b1-a2be-73a9105b0155.woff") format("woff"),
    url("./fonts/821b00ad-e741-4e2d-af1a-85594367c8a2.ttf") format("truetype"),
    url("./fonts/d3e3b689-a6b0-45f2-b279-f5e194f87409.svg#d3e3b689-a6b0-45f2-b279-f5e194f87409")
      format("svg");
}
@font-face {
  font-weight: 700;
  font-family: "DIN Next";
  src: url("./fonts/81cd3b08-fd39-4ae3-8d05-9d24709eba84.eot?#iefix");
  src: url("./fonts/81cd3b08-fd39-4ae3-8d05-9d24709eba84.eot?#iefix")
      format("eot"),
    url("./fonts/31704504-4671-47a6-a61e-397f07410d91.woff2") format("woff2"),
    url("./fonts/b8a280da-481f-44a0-8d9c-1bc64bd7227c.woff") format("woff"),
    url("./fonts/276d122a-0fab-447b-9fc0-5d7fb0eafce2.ttf") format("truetype"),
    url("./fonts/8fb8273a-8213-4928-808b-b5bfaf3fd7e9.svg#8fb8273a-8213-4928-808b-b5bfaf3fd7e9")
      format("svg");
}
@font-face {
  font-family: "DIN Next";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/00cc6df3-ed32-4004-8dd8-1c576600a408.eot?#iefix");
  src: url("./fonts/00cc6df3-ed32-4004-8dd8-1c576600a408.eot?#iefix")
      format("eot"),
    url("./fonts/4132c4c8-680c-4d6d-9251-a2da38503bbd.woff2") format("woff2"),
    url("./fonts/366401fe-6df4-47be-8f55-8a411cff0dd2.woff") format("woff"),
    url("./fonts/dbe4f7ba-fc16-44a6-a577-571620e9edaf.ttf") format("truetype"),
    url("./fonts/f874edca-ee87-4ccf-8b1d-921fbc3c1c36.svg#f874edca-ee87-4ccf-8b1d-921fbc3c1c36")
      format("svg");
}

.app-contents {
  font-family: "DIN Next";
}
