@import "../../css/theme-variables.scss";
.print-event {
  padding: 40px;
  background: $gray;

  .section {
    padding: 1rem 0rem;
  }
  .columns:last-child {
    margin: 0px;
  }
  .title {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  .page-label {
  }
  .page-value {
  }
  .event-block {
    border: 2px solid black;
  }
  .event-title {
    font-size: 22px;
    font-weight: bold;
    padding: 20px;
  }
  .event-label {
    font-size: 12px;
  }
  .event-value {
    font-size: 20px;
  }
  .event-box {
    padding: 20px;
    &.lines-top {
      border-top: 2px solid black;
    }
    &.lines-right {
      border-right: 2px solid black;
    }
  }
  ul.contacts {
    font-size: 12px;
    li {
      input {
        margin-right: 5px;
      }
      &:first-child {
        font-weight: bold;
      }
    }
  }
}
@media screen {
  .print-message {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: white;
    margin: 0px auto 40px auto;
    max-width: 21.59cm;
    .morePrintingInfo {
      font-weight: normal;
      font-size: 18px;
    }
  }
  .print-page {
    background: white;
    margin: 0px auto;
    max-width: 21.59cm;
    height: 27.94cm;
    padding: 40px;
  }
}
@media print {
  .print-message {
    display: none;
  }
  @page {
    // set top and bottom margin to 15mm. left & right margin
    // can be handled automatically by setting content width
    margin: 15mm 0mm 15mm 0mm;
  }
}
/* Target IE10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    margin: auto;
  }
}
